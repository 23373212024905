* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: rgb(3, 15, 56);
}

html {
  font-size: 14px;
  font-family: $common-font;
  font-weight: 500;
  background: #fdf7fa;
}

li {
  list-style-type: none;
}
h2 {
  font-size: 28px;
  line-height: 1.2;
}

a {
  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:link {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}

p {
  margin: 0;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

picture {
  line-height: 0;
}
