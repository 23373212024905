.generate-sold-out {
  display: flex;
  gap: 44px;
  align-items: center;
  margin-bottom: 70px;
  flex-wrap: wrap;
  justify-content: center;

  &__link {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 24px 47px;
    background-color: #8c60bb;
    border-radius: 100px;
    font-weight: 800;
    font-size: 20px;
    line-height: 115%;
    box-shadow: 0px 0px 30px $purpure-color;
    transition: all 0.3s ease-in-out;
    min-width: 280px;
    margin-left: 15px;
    color: $white;
    &:hover {
      background-color: $purpure-color-hover;
      box-shadow: 0px 0px 30px $purpure-color-hover;
    }

    @include for-phone {
      margin-left: 0;
    }
  }

  &__text {
    font-size: 50px;
    line-height: 115%;
    color: $white;
    
  }

  &__img {
    background-color: #8c60bb;
    width: 24px;
    height: 24px;
    &:hover {
      background-color: $purpure-color-hover;
    }
  }
}
