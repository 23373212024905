.buy-more {
  position: relative;
  height: fit-content;

  &::before {
    z-index: 1;
    position: absolute;
    top: 40px;
    left: 42px;
    content: '';
    width: 1px;
    height: 80%;
    border-left: 3px dotted #c4c4c486;
  }

  &__top {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    z-index: 15;

    &-text {
      position: absolute;
      width: 150px;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #c4c4c4;
      bottom: -55px;
      left: 50%;
      text-align: center;
      transform: translateX(-50%);
      margin-left: 20px;
    }
  }

  &__center {
    position: relative;
    z-index: 10;
    width: fit-content;
    align-items: center;
    display: flex;
    gap: 50px;
    margin-top: 83px;

    @include for-tablet {
      margin-top: 45px;
    }

    &::before {
      z-index: -1;
      position: absolute;
      left: 42px;
      content: '';
      width: 100px;
      height: 1px;
      border-top: 3px dotted #c4c4c486;
    }
  }

  &__bottom {
    position: relative;
    z-index: 10;
    margin-top: 62px;
    display: flex;
    align-items: center;
    gap: 50px;
    width: fit-content;

    @include for-tablet {
      margin-top: 30px;
    }

    &::before {
      z-index: -1;
      position: absolute;
      left: 42px;
      content: '';
      width: 100px;
      height: 1px;
      border-top: 3px dotted #c4c4c486;
    }
  }

  &__wrapper {
    padding: 4px;
    background-color: rgb(3, 15, 56);
  }

  &__count {
    width: 78px !important;
    height: 78px !important;
  }

  &__message {
    opacity: 1;
    position: absolute;
    top: 0px;
    right: 55px;
    padding: 10px;
    background-color: $purpure-color;
    z-index: 1;
    border-radius: 100px;
    animation: 3s linear 1 normal forwards showMsg;
  }
}

@keyframes showMsg {
  0% {
    opacity: 0;
    top: 0px;
  }
  20% {
    opacity: 1;
    top: 90px;
  }
  80% {
    opacity: 1;
    top: 90px;
  }
  100% {
    opacity: 0;
  }
}
